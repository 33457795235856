import { Dispatch } from 'react';
import { DefaultRootState } from 'react-redux';
import { AxiosResponse } from 'axios';
import { AnyAction } from 'redux';
import { ThunkAction } from 'redux-thunk';

import { makeSetStoreField } from '@hh.ru/redux-create-reducer';

import { FoldersInvariants } from 'lux/models/resumeFolders';
import fetcher from 'lux/modules/fetcher';
import defaultError from 'src/components/Notifications/DefaultError';
import type { AddNotification } from 'src/components/Notifications/Provider/types';

import { nameIsEmptyError, nameIsTooLongError } from 'src/components/ResumeFolders/CreateFolderNotification';

const resumesFoldersAction = makeSetStoreField('resumeFolders');

declare global {
    interface FetcherPostApi {
        '/employer/resumefolders/create_folder': {
            queryParams: void;
            body: {
                name: string;
            };
            response: { folderId: number };
        };
    }
}
export default (
        {
            newFolderName,
        }: {
            newFolderName: string;
        },
        addNotification: AddNotification
    ): ThunkAction<Promise<{ id: string; updatedFolders: FoldersInvariants }>, DefaultRootState, unknown, AnyAction> =>
    (dispatch: Dispatch<unknown>, getState: () => DefaultRootState) => {
        const resumeFolders = getState().resumeFolders;

        return fetcher.postFormData('/employer/resumefolders/create_folder', { name: newFolderName }).then(
            ({ data }) => {
                const folderId = data.folderId.toString();
                const updatedFoldersInvariants = {
                    ...resumeFolders.foldersInvariants,
                    own: {
                        ...resumeFolders.foldersInvariants.own,
                        [`${folderId}`]: { name: newFolderName },
                    },
                };
                dispatch(
                    resumesFoldersAction({
                        ...resumeFolders,
                        foldersInvariants: updatedFoldersInvariants,
                    })
                );

                return { id: folderId, updatedFolders: updatedFoldersInvariants };
            },
            ({ response }: { response: AxiosResponse<{ error?: { key?: string }[] }> }) => {
                if (response.status === 400) {
                    const errorKey = response.data.error?.[0].key;

                    switch (errorKey) {
                        case 'NAME_IS_EMPTY':
                            addNotification(nameIsEmptyError);
                            break;
                        case 'NAME_IS_TOO_LONG':
                            addNotification(nameIsTooLongError);
                            break;
                        default:
                            addNotification(defaultError);
                    }
                } else {
                    addNotification(defaultError);
                }

                return Promise.reject();
            }
        );
    };
