import urlParser from 'bloko/common/urlParser';

import { DisplayType } from 'lux/models/displayType';

export const WEB_SITE_FALLBACK_TIMER_MS = 300;
export const CHAT = 'CHAT';
export const PHONE = 'PHONE';

export interface DeepLinks {
    deep: string;
    ios?: string;
    android?: string;
    shallow: string;
}

export interface MessengerLinks {
    whatsapp: DeepLinks;
    viber: DeepLinks;
    telegram: DeepLinks;
}

export type MessengersTypes = 'telegram' | 'viber' | 'whatsapp';

export interface GetMessengerLinksParams {
    phone: string;
    accountName?: string;
    displayType: DisplayType | null;
    viberType?: typeof CHAT | typeof PHONE;
    waStartText?: string | null;
}

const getAccountOrPhone = (account: string, phone: string) => (account !== '' ? account : phone);

export const getMessengerLinks = ({
    phone,
    accountName = '',
    displayType,
    viberType = PHONE,
    waStartText = null,
}: GetMessengerLinksParams): MessengerLinks => {
    const viberChatURI = `pa?chatURI=${accountName}`;
    const viberChatNumber = `chat?number=${phone}`;
    const waParams = {
        phone,
        text: waStartText,
    };
    const waParamsQs = urlParser.stringify(waParams);
    const resourceType = accountName ? 'domain' : 'phone';

    return {
        whatsapp: {
            deep: `whatsapp://send?${waParamsQs}`,
            shallow:
                displayType === 'DESKTOP'
                    ? `https://web.whatsapp.com/send?${waParamsQs}`
                    : `https://wa.me/${phone}${waStartText ? `?text=${waStartText}` : ''}`,
        },
        viber: {
            deep: `viber://${viberType === PHONE ? `chat?number=+${phone}` : viberChatURI}`,
            ios: `viber://${viberType === PHONE ? `chat?number=+${phone}` : viberChatURI}`,
            android: `intent://${
                viberType === PHONE ? viberChatNumber : viberChatURI
            }#Intent;scheme=viber;package=com.viber.voip;S.browser_fallback_url=https%3A%2F%2Fviber.com%2Fdownload;end`,
            shallow: 'https://www.viber.com/download/',
        },
        telegram: {
            deep: `tg://resolve?${resourceType}=${getAccountOrPhone(accountName, phone)}`,
            android: `intent://resolve?${resourceType}=${getAccountOrPhone(
                accountName,
                phone
            )}#Intent;scheme=tg;package=org.telegram.messenger;S.browser_fallback_url=https://t.me/${getAccountOrPhone(
                accountName,
                `+${phone}`
            )};end`,
            shallow: `https://t.me/${getAccountOrPhone(accountName, `+${phone}`)}`,
        },
    };
};
