import { ChangeEventHandler } from 'react';

import { VacancySearchItem } from 'lux/models/vacancySearch/vacancySearchItem.types';

export enum VideoCallDuration {
    QuaterAnHour = '15',
    HalfAnHour = '30',
    ThreeQuatersAnHour = '45',
    Hour = '60',
    HourAndAHalf = '90',
    TwoHours = '120',
}

export interface FormData {
    duration: VideoCallDuration;
    comment: string;
    time: string;
    date: string;
    vacancyId: string;
    vacancyName: string;
}

export interface Applicant {
    name: string;
    lastName: string;
    resumeHash: string;
}

export interface VideoCallVacancyData {
    value: string;
    onChange: (vacancyId: string | number) => void;
    list: VacancySearchItem[];
}
export interface VideoCallDateData {
    value: string;
    onChange: (value: string) => void;
    currentAvailable: string;
}

export interface VideoCallInputDateData {
    value: string;
    onChange: (value: string) => void;
    currentAvailable: string;
}

export interface VideoCallTimeData {
    value: string;
    onChange: (value: string) => void;
}

export interface VideoCallDurationData {
    value: string;
    onChange: ChangeEventHandler;
}

export interface VideoCallCommentData {
    value: string;
    onChange: ChangeEventHandler;
}
