import { ComponentPropsWithoutRef, FC } from 'react';

import { useSelector } from 'lux/modules/useSelector';
import Form from 'src/components/Form';

import AddToBlackListField from 'src/components/ResumeComplaint/ResumeComplaintForm/AddToBlackListField';
import ComplaintReasonCommentField from 'src/components/ResumeComplaint/ResumeComplaintForm/ComplaintReasonCommentField';
import ComplaintReasonField from 'src/components/ResumeComplaint/ResumeComplaintForm/ComplaintReasonField';

import styles from './resume-complaint-form.less';

const ResumeComplaintForm: FC<ComponentPropsWithoutRef<'form'>> = (props) => {
    const complaintReasonTypes = useSelector((state) => state.complaintReasonTypes);

    return (
        <Form {...props}>
            <div className={styles.complaintReasonFieldWrapper}>
                <ComplaintReasonField complaintReasonTypes={complaintReasonTypes} />
                <ComplaintReasonCommentField />
            </div>
            <AddToBlackListField />
        </Form>
    );
};

export default ResumeComplaintForm;
