import { PfpDetails, PfpStatus } from 'lux/models/payForPerformance/pfpInfo';
import { useSelector } from 'lux/modules/useSelector';

type UsePfpInfoHook = () => {
    isPfpAvailable: boolean;
    pfpDetails?: PfpDetails;
};

export const usePfpInfo: UsePfpInfoHook = () => {
    const pfpInfo = useSelector((state) => state.pfpInfo);
    const isPfpAvailable = pfpInfo?.pfpStatus === PfpStatus.Enabled;
    const result: ReturnType<UsePfpInfoHook> = {
        isPfpAvailable,
    };
    if (isPfpAvailable && pfpInfo.pfpDetails) {
        result.pfpDetails = pfpInfo.pfpDetails;
    }

    return result;
};
