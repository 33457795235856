import { createContext } from 'react';

import { ToggleState } from 'lux/hooks/useToggleState';
import { EmployerStateExtName, type EmployerStateType } from 'lux/models/negotiationTopic.types';
import type { ResumeCardData } from 'lux/models/resumeCard';

type ResumeCardActionsContextType = {
    chatId: number;
    commentToggle: ToggleState;
    isChangeState: boolean;
    isDiscard: boolean;
    isFirstResponse: boolean;
    isResponse: boolean;
    messagesToggle: ToggleState;
    resume: ResumeCardData;
    resumeStateUpdate: (state: EmployerStateType, update: string) => void;
    markAsRead: undefined | (() => void);
    setHidden: (val: boolean) => void;
    isNew: boolean;
    isGiftedResume: boolean;
    isFirstGift: boolean;
    vacancyId: string;
    isReadOnly?: boolean;
    showAdditional?: boolean;
    lastEmployerStateExtName?: EmployerStateExtName;
    isButton?: boolean;
    isSelected: boolean;
    select: () => void;
    conversationMessagesCount: number;
    conversationUnreadByEmployerCount: number;
    isFoldersPage: boolean;
    isSuitableResume: boolean;
    isPfpVacancy: boolean;
    onResumeUpdate: (resume: ResumeCardData) => void;
};

const INITIAL_STATE = { resume: {} };

export const ResumeCardActionsContext = createContext<ResumeCardActionsContextType>(
    INITIAL_STATE as ResumeCardActionsContextType
);
