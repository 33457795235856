import resumeCommentButtonClick from '@hh.ru/analytics-js-events/build/xhh/employer/resume/resume_comment_button_click';
import Button from 'bloko/blocks/button';
import Link from 'bloko/blocks/link';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import { useSelector } from 'lux/modules/useSelector';
import { useNotification } from 'src/components/Notifications/Provider';
import { resumeCommentAddMessage } from 'src/components/Notifications/ResumeComments';
import CommentFormEdit from 'src/components/ResumeCard/Actions/Comments/Comment/FormEdit';
import translation from 'src/components/translation';

import Form from 'src/components/ResumeComments/Form';
import { AddProps } from 'src/components/ResumeComments/types';

const TrlKeys = {
    add: 'resume.comments.add',
};

const Add: TranslatedComponent<AddProps> = ({ trls, hide, user, onAdd, editId, setEditId, addFromCard }) => {
    const resumeId = useSelector((state) => state.resume?.id);
    const vacancyId = useSelector((state) => state.router.location.query.vacancyId);
    const { addNotification } = useNotification();

    if (hide) {
        return null;
    }

    if (editId === 'add') {
        return (
            <Form
                renderWrapper={addFromCard ? CommentFormEdit : undefined}
                user={user}
                onSubmitSuccessful={(response) => {
                    onAdd(response);
                    setEditId(null);
                    addNotification(resumeCommentAddMessage, { props: { message: response.body } });
                }}
                onCancel={() => setEditId(null)}
            />
        );
    }

    return (
        <div className="resume-sidebar-link">
            <Link
                Element={Button}
                onClick={() => {
                    resumeCommentButtonClick({ vacancyId, resumeId });
                    setEditId('add');
                }}
                data-qa="add-comment"
            >
                {trls[TrlKeys.add]}
            </Link>
        </div>
    );
};

export default translation(Add);
