import { DefaultRootState } from 'react-redux';

import { VacancyPermission } from 'lux/models/vacancy/permissions.types';

export const selectorCanCommunicateWithApplicant = (state: DefaultRootState): boolean => {
    const isHiringManagerExperiment = state.isHiringManagerExperiment;
    const isHiringManager = state.isHiringManager;
    const vacancyPermission = state.hiringManagerVacancyPermission;

    if (!isHiringManagerExperiment || !isHiringManager) {
        return true;
    }

    return !!vacancyPermission?.permissions.includes(VacancyPermission.ViewContacts);
};
