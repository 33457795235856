import { Dispatch } from 'react';
import { DefaultRootState } from 'react-redux';
import { AnyAction } from 'redux';
import { ThunkAction } from 'redux-thunk';

import { complaintReasonTypesAction, ComplaintReasonTypeDoggyStyle } from 'lux/models/complaintReasonTypes';
import fetcher from 'lux/modules/fetcher';
import defaultRequestErrorHandler from 'lux/requests/notifications/defaultRequestErrorHandler';
import { AddNotification } from 'src/components/Notifications/Provider/types';

const NEGOTIATION_COMPLAINT = '/shards/employer/negotiation_complaint';
declare global {
    interface FetcherGetApi {
        [NEGOTIATION_COMPLAINT]: {
            queryParams: { position: string; isLux: boolean };
            response: { reason: ComplaintReasonTypeDoggyStyle[] };
        };
    }
}

export default (
        addNotification: AddNotification
    ): ThunkAction<Promise<unknown>, DefaultRootState, unknown, AnyAction> =>
    (dispatch: Dispatch<unknown>) => {
        return fetcher.get(NEGOTIATION_COMPLAINT).then(
            ({ reason }) => {
                dispatch(complaintReasonTypesAction(reason));
            },
            (err) => defaultRequestErrorHandler(err, addNotification)
        );
    };
